import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Visitor-app';
  online: boolean;
  isNetworkStopped = false;
  
  constructor(private router: Router) {
    this.online = window.navigator.onLine;
    this.isNetworkStopped = !this.online;
    fromEvent(window, 'online').subscribe(e => {
      console.log("App is online");
      this.online = true;
      this.isNetworkStopped = !this.online;
    });
    fromEvent(window, 'offline').subscribe(e => {
      console.log("App is offline");
      this.online = false;
      this.isNetworkStopped = !this.online;
    });
  }

  ngOnInit() {

  }
  
}
