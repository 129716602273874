<div class="profile-popup">
    <div class="clear-both">
        <button class="logout-button dflex align_ctr" (click)="logout()">
            <img src="assets/images/signout.png" alt="" width="20px" height="20px">
            <!-- <i class="fa fa-sign-out" aria-hidden="true"></i> -->
            <span class="logout" >Logout</span>
        </button>
    </div>
    <div class="loggedin-list clear-both">
        <p class="fw-title">Fire wardens logged in now...</p>
        <div class="scrollStyle">
            <div class="fire-wardens " *ngFor="let warden of wardenList">
                <p class="fw-name">{{warden.fullName}}</p>
                <a class="fw-number" href="{{'tel:' + getPhoneNumber(warden)}}">{{getPhoneNumber(warden)}}</a>
            </div>
        </div>
    </div>
    <button mat-raised-button class="okay_btn fs-20" color="primary" (click)="dialogRef.close()">OK</button>
</div>