
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './components/core/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/core/auth-layout/auth-layout.component';
import { HomeComponent } from './pages/home/home.component';
import {AuthGuard} from './guards/auth.guard'
import {AdminGuard} from './guards/admin.guard'


export const AppRoutes: Routes = [{
    path: "",
    redirectTo: "session/signin",
    pathMatch: "full"
},
{
    path: "",
    component: AdminLayoutComponent,
    children: [
        {
            path: "home",
            loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
        },
        {
            path: "home/:visitId/:passType",
            loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
        },
        {
            path: "home/:visitId",
            loadChildren: () => import("./pages/home/home.module").then(m => m.HomeModule)
        },
    ],
    canActivate: [AuthGuard],
},
{
    path: "",
    component: AuthLayoutComponent,
    children: [{
        path: "session",
        loadChildren: () => import("./pages/session/session.module").then(m => m.SessionModule)
    },
    ],
    canActivate: [AdminGuard],
}
];