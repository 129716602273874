<div class="d-flex justify-content-evenly flex-column h-100">
    <div *ngIf=" getStorage()">
        <p class="select-label">Select Account...</p>
        <mat-form-field class="role nativeField mt-2 mb-15" floatLabel="never">
            <mat-label>Select Account...</mat-label>
            <mat-select name="role" (selectionChange)="changeAccount($event)">
                <mat-option class="uregular" *ngFor="let account of accountList" [value]="account.orgID">
                    {{account.organizationName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <p class="select-label">Select Site...</p>
        <div *ngIf="isLoading" class="d-flex justify-content-center">
            <mat-spinner [diameter]="30" [color]="'primary'"></mat-spinner>
        </div>
        <mat-form-field class="role nativeField mt-2 mb-15" floatLabel="never" *ngIf="!isLoading">
            <mat-label>Select Site...</mat-label>
            <mat-select name="role" (selectionChange)="changeSite($event)" >
                <mat-option class="uregular" *ngFor="let site of siteList" [value]="site.siteId">
                    {{site.siteName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>