const dev = {
      production: false,
      url: "https://vpassso.hakunamatata.io",
      loginUrl: "https://vpassitwt.hakunamatata.io",
      visitorAppUrl: "https://vpassmobile.hakunamatata.io",
      cdnUrl: "https://vpasscdn.hakunamatata.io/api/File",
      kioskUrl: "https://vpasskioskapp.hakunamatata.io",
      socketUrl: "vpasssocket.hakunamatata.io/v1",
      region: "au"
  }
  
  export const environment = dev;