<div class="app-signout-reports w-100">
    <div class="signout-today">
        <a (click)="dialogRef.close({action: true})">Close</a>
        <h2>Signed Out Today</h2>
    </div>
    <section class="no-data" *ngIf="!visitorList.length">
        <div>
            No Records Found...
        </div>
    </section>
    <section class="expension-div" *ngIf="visitorList.length">
        <div class="accordion" id="accordionExampleSignout">
        <div *ngFor="let visitor of visitorList; let i = index">
                <div class="card">
                    <div class="card-header">
                        <div data-toggle="collapse" [attr.data-target]="'#collapseOneSignout' + i" >
                            <div>
                                <span class="title">{{visitor.visitorFirstName}}  {{visitor.visitorLastName}}</span>
                                <span class="accicon"><i class="fas fa-angle-down rotate-icon"></i></span>
                            </div>
                        </div>
                        <div class="header-phone-content">
                            <div>
                                <a href="{{'tel:' + getPhoneNumber(visitor) }}">{{getPhoneNumber(visitor)}}</a>
                            </div>
                        </div>
                    </div>
                    <div [id]="'collapseOneSignout' + i" class="collapse" data-parent="#accordionExampleSignout">
                        <div class="card-body">
                            <div class="row">
                                <div class="">
                                    <ul class="name-list">
                                        <li>
                                            <b>Visitor Type</b> : <span>{{visitor.visitorType}}</span>
                                        </li>
                                        <li>
                                            <b>Location</b> : <span>{{visitor.LocationName}}</span>
                                        </li>
                                        <li>
                                            <b>Time</b> : <span>{{visitor.signedOutSiteTime | date :'dd-MM-yyyy h:mm a'}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>

