import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AdminLayoutComponent } from './components/core/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/core/auth-layout/auth-layout.component';
import { SiteListComponent } from './components/core/sitelist/sitelist.component';
import { ProfilePopupComponent } from './components/core/profile-popup/profile-popup.component';
import { EmergencyPopupComponent } from './components/core/emergency-popup/emergency-popup.component';
import { ConfirmdialogComponent } from './components/core/confirmdialog/confirmdialog.component';
import { SignedOutListComponent } from './components/core/singed-out-list/signed-out-list.component';
import { SortListPopupComponent } from './components/core/sort-list-popup/sort-list-popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonInterceptor } from './services/common.interceptor';
import { ErrorService } from './services/error.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PipesModule } from './pipes/pipes.module';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'



@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    SiteListComponent, ProfilePopupComponent, EmergencyPopupComponent, ConfirmdialogComponent, SignedOutListComponent, SortListPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    MatSelectModule,
    MatDialogModule,
    MatSnackBarModule,
    MatSidenavModule,
    HttpClientModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule,
    PipesModule,
    MatProgressSpinnerModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: CommonInterceptor,
    multi: true
  }, 
  ErrorService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
