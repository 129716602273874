import { Component, OnInit, ViewChild, HostListener, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { Clipboard } from "@angular/cdk/clipboard"
import { Router } from '@angular/router';
import * as moment from 'moment';
import { PagesService } from 'src/app/services/pages.service';


@Component({
    selector: 'app-signed-out-list',
    templateUrl: './signed-out-list.component.html',
    styleUrls: ['./signed-out-list.component.scss'],
  
})
export class SignedOutListComponent implements OnInit {
  
  locale: any = {
    "format": "YYYY-MM-DDTHH:mm:ss", "displayFormat": "DD-MM-YYYY", "separator": " to ", "cancelLabel": "Cancel", "applyLabel": "Okay"
  };
  visitorList: any = [];
  visitorCount: number = 0;
  reportsCount: any = {};
  reqObj: any = {};

  constructor( public dialog: MatDialog, private router: Router, public dialogRef: MatDialogRef<SignedOutListComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
      this.visitorList = data.signedOutList;
  }

  ngOnInit(): void {
  }

  getPhoneNumber(visitor){
    return visitor.PhoneNumber? visitor.PhoneNumber:''  ;
  }

}


