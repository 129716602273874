import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/services/error.service';
import { PagesService } from 'src/app/services/pages.service';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})
export class ProfilePopupComponent implements OnInit {
  public wardenList: any = [];
    

  constructor(public dialogRef: MatDialogRef<ProfilePopupComponent>, private router: Router, private errService: ErrorService, @Inject(MAT_DIALOG_DATA) public data: any , private pageService: PagesService) { 
    this.wardenList = data.firewardenList
    }

    ngOnInit() {
    }

  logout() {
    this.dialogRef.close();
    localStorage.clear();
    this.router.navigate(['/session/signin']);
    this.errService.showSuccessMsg('Logged out successfully');
  }

  getPhoneNumber(warden){
    return warden.dial ? `+${warden.dial} ${warden.phonenumber}` : `${warden.phonenumber}`;
  }

}
