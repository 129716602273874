import { Component, OnInit, Inject  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmdialog',
  templateUrl: './confirmdialog.component.html',
  styleUrls: ['./confirmdialog.component.scss']
})
export class ConfirmdialogComponent implements OnInit {
  public isCancel: boolean = false;

  constructor(public dialogRef: MatDialogRef<ConfirmdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if (this.data.fromPage == 'home_component' || this.data.fromPage == 'email_component') {
      this.isCancel = true;
    }
  }

  handleKeyboardEvents(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.dialogRef.close({ action: true });
    }
  }

  cancel(): void {
    this.dialogRef.close({ action: false });
  }

  okay() {
    this.dialogRef.close({ action: true });
  }

}
