<div [ngClass]="!selectedMessage ? 'emergency-popup':''">
    <a class="skip pointer" *ngIf="!selectedMessage" (click)="dialogRef.close()">Skip</a>
    <a class="skip pointer" *ngIf="selectedMessage && !recipientCount" (click)="dialogRef.close()">Close</a>
    <div class="exclamation-image">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
    </div>
    <p class="er-msg">{{errorMessage}}</p>
    <div *ngIf="isLoading" class="d-flex justify-content-center">
        <mat-spinner [diameter]="30" [color]="'primary'"></mat-spinner>
    </div>
    <mat-form-field class="role nativeField mt-15 mb-15 emgSelectField" floatLabel="never"  *ngIf="!selectedMessage && !isLoading">
        <mat-select name="role" (selectionChange)="changeMessage($event)" disableOptionCentering placeholder="Select...">
            <!-- Option group for Visitor Message -->
            <mat-optgroup *ngIf="messageType1Messages && messageType1Messages.length > 0" label="Visitor Message">
                <mat-option class="uregular" *ngFor="let message of messageType1Messages" [value]="message.messageId + '~' + 'Visitor Message'" [data-group]="{name: 'Visitor Message'}">
                    {{message.messageName}}
                </mat-option>
            </mat-optgroup>
            <!-- Option group for Fire Warden Message -->
            <mat-optgroup *ngIf="messageType2Messages && messageType2Messages.length > 0" label="Fire Warden Message">
                <mat-option class="uregular" *ngFor="let message of messageType2Messages" [value]="message.messageId + '~' + 'Fire Warden Message'" [data-group]="{name: 'Fire Warden Message'}">
                    {{message.messageName}}
                </mat-option>
            </mat-optgroup>
            <!-- Option group for Employee Message -->
            <mat-optgroup *ngIf="messageType3Messages && messageType3Messages.length > 0" label="Employee Message">
                <mat-option class="uregular" *ngFor="let message of messageType3Messages" [value]="message.messageId + '~' + 'Employee Message'" [data-group]="{name: 'Employee Message'}">
                    {{message.messageName}}
                </mat-option>
            </mat-optgroup>
        </mat-select>
    </mat-form-field>
    <div *ngIf="messageList?.length == 0 && !isLoading" class="text-center fs-18">
        No data in the dropdown
    </div>
    <div class="send-msg-buttons d-flex justify-content-evenly" *ngIf="selectedMessage && recipientCount">
        <button mat-raised-button class="cancel_btn" color="primary" (click)="dialogRef.close()">Cancel</button>
        <button mat-raised-button class="send_btn" color="primary" (click)="sendSMS()" [disabled]="disableSendBtn" >Send SMS</button>
    </div>
</div>