import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { PagesService } from 'src/app/services/pages.service';
import { ResponseMsg } from '../../../interface/response-msg';
import { ErrorService } from '../../../services/error.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

  selectedPage = 'Home';
  @ViewChild('drawer') drawer: MatDrawer;
  userDetail = localStorage.getItem('user');
  public logoutmsg = ResponseMsg.LOGOUT_MSG;
  public isMenu: boolean = false;

  constructor(private router: Router, private errService: ErrorService, private pageService: PagesService) { }

  ngOnInit(): void {
    
    let currentPage = this.router.url.split('/');
    this.selectedPage = currentPage[1].charAt(0).toUpperCase() + currentPage[1].slice(1);
  }

}
