import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmdialogComponent } from 'src/app/components/core/confirmdialog/confirmdialog.component';
import { ErrorService } from 'src/app/services/error.service';
import { PagesService } from 'src/app/services/pages.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-emergency-popup',
  templateUrl: './emergency-popup.component.html',
  styleUrls: ['./emergency-popup.component.scss']
})
export class EmergencyPopupComponent implements OnInit {
  messageList: any = [];
  selectedMessage: any;
  visitorList: any;
  errorMessage = 'Send Emergency Message';
  isLoading:boolean = false;
  fireWardenCount:any = [];
  fireWardenMsgType = 2;
  disableSendBtn: boolean = false;
  recipientCount =0;
  selectedOptionGroup: any;
  hostReceiveEmgMsg = false;
  messageType1Messages: any= [];
  messageType2Messages: any= [];
  messageType3Messages: any= [];

  constructor(public dialogRef: MatDialogRef<EmergencyPopupComponent>, private router: Router, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private pageService: PagesService, private errService: ErrorService, private CommonService:CommonService) { 
    this.CommonService.visitorList.subscribe((res: any) => {
      this.visitorList = res
    })
    this.CommonService.firewardenList.subscribe((res:any)=>{
      this.fireWardenCount = res.length-1;
    })
    this.CommonService.siteList.subscribe((res:any)=>{
      let siteId = localStorage.getItem('sId');
     this.hostReceiveEmgMsg= res.filter(item => item.siteId == siteId)[0].isHostEmergencyNotify;
    })
  }

  ngOnInit() {
    this.getEmergencyMessageList();
  }

  getEmergencyMessageList() {
    let siteId = localStorage.getItem('sId');
    this.isLoading = true;
    this.pageService.getEmergencyMessagess(siteId).subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isLoading = false;
        let msgList = res.messageList;
        //this.messageList=  [...msgList.filter(item=>item.messageType == 1).sort((a,b)=> a.messageName.localeCompare(b.messageName)),... msgList.filter(item=>item.messageType == 2).sort((a,b)=> a.messageName.localeCompare(b.messageName))]

         // Split messageType string by comma and then check if it contains the target value
         const messageTypeContains = (messageType, target) => messageType.split(',').includes(target);

         // Filter and sort messages with messageType containing "1"
         this.messageType1Messages = msgList.filter(item => messageTypeContains(item.messageType, "1")).sort((a, b) => a.messageName.localeCompare(b.messageName));
 
         // Filter and sort messages with messageType containing "2"
         this.messageType2Messages = msgList.filter(item => messageTypeContains(item.messageType, "2")).sort((a, b) => a.messageName.localeCompare(b.messageName));

         // Filter and sort messages with messageType containing "3"
         this.messageType3Messages = msgList.filter(item => messageTypeContains(item.messageType, "3")).sort((a, b) => a.messageName.localeCompare(b.messageName));
 
         // Concatenate the sorted arrays into one array
         this.messageList = [...this.messageType1Messages, ...this.messageType2Messages, ...this.messageType3Messages];
 
    
      } else {
        this.isLoading =false;
        this.errService.showResponseError(res.statusMessage); 
      }
    }, err => {
      this.isLoading =false;
      this.errService.showError(err);
    })
  }

  changeMessage(event) {
    this.selectedOptionGroup = event.value.split('~')[1]; // Get the label of the selected option group
    this.selectedMessage = event.value.split('~')[0];
    let visitorCount = 0;
    
    if(this.visitorList.length){
      if(this.hostReceiveEmgMsg){
        visitorCount = this.visitorList.filter(item=> item.PhoneNumber || item.hostPhonenumber).length
      } else {
        visitorCount = this.visitorList.filter(item=> item.PhoneNumber).length
      }
    }
    let emgMsg = this.messageList.filter(val => val.messageId == this.selectedMessage);
    this.recipientCount = emgMsg[0].messageType == this.fireWardenMsgType ? this.fireWardenCount : visitorCount;
    if(this.recipientCount){
      this.errorMessage = 'Send ' + emgMsg[0].messageName + ' Message to ' + this.recipientCount + ' Recipients?';
    } else if(this.visitorList.length && !this.recipientCount){
      this.errorMessage = 'No phone numbers for Visitors or Hosts';
    }else {
      this.errorMessage = 'There is no Recipient'
    }
  }

  sendSMS() {
    this.disableSendBtn = true;
    this.dialogRef.close();
    
    let emgMsg = this.messageList.filter(val => val.messageId == this.selectedMessage)[0].messageName
    this.pageService.smsEmergencyMsg({siteId:parseInt(localStorage.getItem('sId')),messageid:this.selectedMessage, type : this.selectedOptionGroup}).subscribe((res:any)=>{
      //need to add message id
      if(res.statusCode == 200){
        let dialogRef = this.dialog.open(ConfirmdialogComponent, {
          width: '350px',
          autoFocus: false,
          data: {
            title: '',
            subtitle: 'The ' + emgMsg + ' Emergency Message has been sent',
            btntext: 'OK'
          }
        });
      } else {
        this.errService.showResponseError(res.statusMessage);
      }
    })

    this.dialogRef.afterClosed().subscribe(res => {
    });
  }

}