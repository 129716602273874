<div class="sort-list-popup">
  <div class="d-flex justify-content-between">
      <div class="sortHeading">Sort Visitors by...</div>
      <p class="close fs-16" (click)="dialogRef.close({action: true})">Close</p>
    </div>
    <div class="sorting-items">
        <div *ngFor="let item of sorting_items" [class]="item.selected ? 'selected-item' : ''">
            <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" checked (click)="$event.preventDefault()" *ngIf="item.selected">
                  <p value="item" (click)="sortItems(item, 'item')">{{item.name}}</p>
                </label>
              </div>
        </div>
    </div>
    <hr>
    <div class="sorting-items">
      <div class="sortHeading">Filter by Location...</div>
        <div *ngFor="let location of locationItems_items" [class]="location.selected ? 'selected-item' : ''">
            <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" checked (click)="$event.preventDefault()" *ngIf="location.selected">
                  <p value="item" (click)="sortItems(location, 'location')">{{location.locationName}}</p>
                </label>
              </div>
        </div>
    </div>
</div>