import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from './common.service';
import { map } from 'rxjs/operators';
import { LoaderService } from '../sharedmodules/loader.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  
  public visitList: any;
  public reportsCount: any;
  public signOutList: any;
  public signOutreportsCount: any;
  public response: any;

  constructor(private httpClient: HttpClient, private loaderService: LoaderService,
    private errService: ErrorService, private router: Router) { }

    getSiteList() {
      let reqUrl = CommonService.getApiUrl('/reports/site/location');
      return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
    }  
    
    emergencyReportList(data) {
      let reqUrl = CommonService.getApiUrl('/reports/emergency/list');
      return this.httpClient.post(reqUrl, data).pipe(map((res: Response) => {
        this.response = res;
        this.visitList = this.response.visitList;
        this.reportsCount = this.response.reportsCount;
        return res;
      }));
    }

    emergencyUpdateStatus(isChecked, visitId) {
      let reqUrl = CommonService.getApiUrl('/reports/emergency/updateSatus/' + visitId + '/' + isChecked);
      return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
    }

    getSignedOutList(req) {
      let reqUrl = CommonService.getApiUrl('/reports/signedout/list');
      return this.httpClient.post(reqUrl, req).pipe(map((res: Response) => res));
    }

    getEmergencyMessagess(siteId) {
      let reqUrl = CommonService.getApiUrl('/Emergency/emergencymessage/list?siteId=' + siteId);
      return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
    }

    getFireWardens(siteId) {
      let reqUrl = CommonService.getApiUrl('/Emergency/firewarden/list?siteID=' + siteId);
      return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
    }

    getAllOrganisationList(data){
      const reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('initAuth'),
      });
      let reqUrl =  CommonService.getApiUrl('/Groups/fulllist');
      return this.httpClient.post(reqUrl, data,{ headers: reqHeader }).pipe(map((res: Response) => res));
    }

    getUserProfile(){
      let reqUrl = CommonService.getApiUrl('/emergency/userDetails');
      return this.httpClient.get(reqUrl).pipe(map((res: Response) => res));
    }

    getOrgToken(data) {
      let reqUrl =CommonService.getApiUrl('/Groups/getOrgToken');
      const reqHeader = new HttpHeaders({ 
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + localStorage.getItem('initAuth'),
        'clienttoken': data.orgToken
      });
      return this.httpClient.get(reqUrl, { headers: reqHeader });
    }

  emailEmergencyReport(req) {
    let reqUrl = CommonService.getApiUrl('/reports/emergency/export');
    return this.httpClient.post(reqUrl, req).pipe(map((res: Response) => {
      return res;
    }));
  }

  smsEmergencyMsg(req) {
    let reqUrl = CommonService.getApiUrl('/reports/emergency/sendsms?siteId='+req.siteId+'&messageid='+req.messageid+'&type='+req.type);
    return this.httpClient.post(reqUrl, req).pipe(map((res: Response) => {
      return res;
    }));
  }
}
