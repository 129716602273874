import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sort-list-popup',
  templateUrl: './sort-list-popup.component.html',
  styleUrls: ['./sort-list-popup.component.scss']
})
export class SortListPopupComponent implements OnInit {
    public selectLocation = ''
    public selectedItem =''
    public sorting_items = [
    {name: 'OK Checkbox',selected:false},
    {name: 'First Name',selected:false},
    {name: 'Last Name',selected:false},
    {name: 'Visitor Type',selected:false},
    {name: 'Sign In Date & Time',selected:false}
  ];
  public locationItems_items: any = [
    {locationName:'All Locations',locationId:0,selected:false}]
    

  constructor(public dialogRef: MatDialogRef<SortListPopupComponent>, private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.selectLocation = data.selectedLocationName
      this.selectedItem = data.selectedSortItem
      data.locationList.map(item =>{
        this.locationItems_items=[...this.locationItems_items,{...item, selected:false}]
      })
      // this.location_list = data.locationList;  
      // this.selectedItem = data.selectedSortItem;
    }

    ngOnInit() {
      this.sorting_items.forEach(item=>{
        if(item.name === this.selectedItem){
          item['selected'] = true
        }}
        )
        this.locationItems_items.forEach(item=>{
          if(item.locationId === this.selectLocation){
            item['selected'] = true
          }}
        )
      }

    sortItems(sortItem, type) {
      if (sortItem && type == 'item') {
        this.sorting_items.forEach(val=>{
          if(val.name===sortItem.name){
            val['selected'] = true;
          }else{
            val['selected'] = false;
          }
        })
        this.dialogRef.close({ data: sortItem.name, location :this.locationItems_items.filter(item=> item.selected)[0].locationId}); 
      } else if (sortItem && type == 'location') {
    
          this.locationItems_items.forEach(element=>{
              if(element.locationId===sortItem.locationId){
                element['selected'] = true;
              }else{
                element['selected'] = false;
              }
          })
        this.dialogRef.close({data:this.sorting_items.filter(item=> item.selected)[0].name, location : sortItem.locationId });
      }
    }
}
