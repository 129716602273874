import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PagesService } from '../../../services/pages.service';
import { ErrorService } from '../../../services/error.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-sitelist',
  templateUrl: './sitelist.component.html',
  styleUrls: ['./sitelist.component.scss']
})
export class SiteListComponent implements OnInit {
  
    @ViewChild('f') form: any;
    public siteList = [];
    public accountList = [];
    public reqObj: any = {};
    public siteName = '';
    public isLoading = false;

  constructor(public dialogRef: MatDialogRef<SiteListComponent>, private router: Router,private pageService: PagesService,
    private errService: ErrorService, private CommonService : CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any ) { 
        this.accountList = data.accountList ? data.accountList : [];
        this.siteList = data.siteList ? data.siteList : [];
    }

    ngOnInit() {
  }

  handleKeyboardEvents(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.dialogRef.close({ action: true });
    }
  }

  cancel(): void {
    this.dialogRef.close({ action: false });
  }

  okay() {
    this.dialogRef.close({ action: true });
  }

  changeAccount(event) {
    let initAuth = localStorage.getItem('initAuth')
    localStorage.setItem('auth',initAuth)
    let filteredAccount = this.accountList.filter(item => item.orgID == event.value)[0];
    this.pageService.getOrgToken({orgToken:filteredAccount.orgToken}).subscribe((res:any)=>{
      if(res.statusCode == 200){
        localStorage.setItem('auth',res.orgToken)
        this.pageService.getUserProfile().subscribe((res:any) =>{
          if(res.statusCode == 200){
            let user = {
              firstName: res.profileInfo.firstName,
              lastName: res.profileInfo.lastName,
              mobileNo: res.profileInfo.mobileNo,
              userID: res.profileInfo.userID
            }
            localStorage.setItem('auth', res.profileInfo.usertoken);
            localStorage.setItem('user', JSON.stringify(user));
            this.getSiteDetails()
          } else {
            this.errService.showResponseError(res.statusMessage);
          }
        })
      } else {
        this.errService.showResponseError(res.statusMessage);
      }
    })
  }

  getSiteDetails(){
    this.isLoading =true;
    this.pageService.getSiteList().subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.isLoading =false;
        this.siteList = res.siteList;
        this.CommonService.setsiteList(res.siteList)
      } else {
        this.isLoading =false;
        this.errService.showResponseError(res.statusMessage);
      }
    }, err => {
    this.isLoading =false;
    this.errService.showError(err);
  }
  )
  }

  changeSite(event) {
    localStorage.setItem('sId', event.value);
    this.dialogRef.close({ data: localStorage.getItem('sId')});
  }

  getStorage(){
    return localStorage.getItem('isGroup') == 'true' && this.accountList.length
  }

}
